<template>
    <div>
        <p class="uk-text-bold">Working Time Calculation</p>
        <div class="uk-margin-top">
            <p>Table</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WorkingTimeCalculation'
};
</script>
